import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { postCardTypes } from '../types/propTypes';
import { font } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  width: 49%;
  margin-right: 2%;
  margin-bottom: 12rem;
  &:nth-child(2n) {
    margin-right: 0;
  }

  ${mq.tabletSmall`
    width: 100%;
    margin-right: 0;
    margin-bottom: 8rem;
  `}

  button {
    ${font.button};
    width: 21rem;
  }
  .date {
    ${font.p};
    margin-bottom: 1.2rem;
    text-transform: uppercase;
  }
  h2 {
    margin-bottom: 1.8rem;
    text-transform: none;
  }
`;

export default function PostCard({ post }) {
  return (
    <Fragment>
      <Wrapper>
        <div className="date">
          <span>{post.meta.firstPublishedAt}</span>
        </div>
        <h2>{post.title}</h2>
        <Link title={post.title} to={`/blog/${post.slug}/`}>
          <button>
            <span>Read More</span>
          </button>
        </Link>
      </Wrapper>
    </Fragment>
  );
}

PostCard.propTypes = postCardTypes;
