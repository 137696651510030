import React, { Fragment } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import _map from 'lodash/map';
import _chunk from 'lodash/chunk';

import SEO from '../components/SEO';
import PageHero from '../components/PageHero';
import PostCard from '../components/PostCard';
import { Container, Inner } from '../components/Elements';
import mq from '../style/mq';

const PostContainer = styled(Container)`
  ${Inner} {
    width: 90rem;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
`;

const PostGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  &:after {
    content: '';
    height: 0.2rem;
    width: 100%;
    background: white;
    position: absolute;
    bottom: 6rem;
    left: 0;
  }

  ${mq.tabletSmall`
    &:after {
      display: none;
    }
  `}
`;

const blogQuery = graphql`
  {
    page: datoCmsBlogPage {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    posts: allDatoCmsBlogPost(
      sort: { fields: [meta___firstPublishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          meta {
            firstPublishedAt(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  }
`;

export default function Blog() {
  const data = useStaticQuery(blogQuery);
  const { page, posts } = data;

  const postChunks = _chunk(posts.edges, 2);

  return (
    <Fragment>
      <SEO meta={page.seoMetaTags} />
      <PageHero title={page.title} />
      <PostContainer>
        <Inner>
          {_map(postChunks, (groupPosts, i) => (
            <PostGroup key={i}>
              {_map(groupPosts, (pp) => (
                <PostCard key={pp.node.id} post={pp.node} />
              ))}
            </PostGroup>
          ))}
        </Inner>
      </PostContainer>
    </Fragment>
  );
}
